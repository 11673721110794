<template>
  <div class="page-content container-fluid">
    <div class="card borderless">
      <div class="card-header">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('sms.history.lbl-search-id')"
                  v-model="form.id"
                  aria-label
                  aria-describedby="basic-addon2"
                />
                <div class="input-group-append">
                  <button
                    class="btn icon_btn m-0"
                    type="button"
                    @click="fetch(form.page)"
                  >
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!$store.state.account.whitelabel_id"
            class="form-group col-lg"
          >
            <select-input
              label="name"
              v-model="form.customer_ids"
              :reduce="(field) => field.id"
              :options="customers"
              :clearable="true"
              multiple
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-4">
            <template>
              <div>
                <date-range-picker
                  class="form-control"
                  value
                  :options="options"
                  @change="changeDate"
                />
              </div>
            </template>
          </div>
          <div class="col-lg-2 col-md-2">
            <input
              class="form-control"
              :placeholder="$t('generic-str.lbl-sender')"
              v-model="form.from"
            />
          </div>
          <div class="col-lg-2 col-md-2">
            <input
              class="form-control"
              :placeholder="$t('generic-str.lbl-recipient')"
              v-model="form.to"
            />
          </div>
          <div class="col-lg-2 col-md-2">
            <select class="form-control" v-model="form.status">
              <option selected value>Status</option>
              <option value="accepted">
                {{ $tc('generic-str.status.lbl-pending', 1) }}
              </option>
              <option value="sending">
                {{ $t('generic-str.status.lbl-sending') }}
              </option>
              <option value="sent">
                {{ $t('generic-str.status.lbl-sent') }}
              </option>
              <option value="failed">
                {{ $t('generic-str.status.lbl-failure') }}
              </option>
              <option value="delivered">
                {{ $t('generic-str.status.lbl-delivered') }}
              </option>
              <option value="read">
                {{ $t('generic-str.status.lbl-readed') }}
              </option>
              <option value="received">
                {{ $tc('generic-str.status.lbl-response', 2) }}
              </option>
            </select>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="form-group">
              <div class="input-group">
                <div class>
                  <button class="btn btn-yup-purple" @click="fetch(1)">
                    <!-- {{ $t('generic-str.filter') }} -->
                    <span class="material-symbols-outlined"> search </span>
                    <!-- Filtrar -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body block-el p-0">
        <div class="responsive-table">
          <table v-if="fetched" class="table">
            <thead class="">
              <tr>
                <th scope="col">{{ $t('generic-str.date') }}</th>
                <th scope="col">{{ $t('generic-str.type') }}</th>
                <th scope="col">{{ $t('generic-str.lbl-sender') }}</th>
                <th scope="col">{{ $t('generic-str.lbl-recipient') }}</th>
                <th scope="col">{{ $tc('generic-str.message', 1) }}</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="message in messages"
                :key="message.id"
                :to="`history/${message.id}`"
              >
                <td data-label="Data">
                  <div>
                    <router-link :to="`history/${message.id}`">
                      {{ message.sent_at | formatDate24 }}
                    </router-link>
                  </div>
                </td>
                <td data-label="Tipo">
                  <template v-if="message.direction == 'outbound'">
                    {{ $t('generic-str.send-2') }}
                  </template>
                  <template v-else-if="message.direction == 'inbound'">
                    {{ $tc('generic-str.status.lbl-response', 1) }}
                  </template>
                </td>
                <td data-label="Remetente">{{ message.from }}</td>
                <td data-label="Destinatário">{{ message.to }}</td>
                <td
                  data-label="Mensagem"
                  class="max-200"
                  v-tooltip.top="`${message.content}`"
                >
                  {{ message.content }}
                </td>
                <td data-label="Status">
                  <span
                    v-if="message.status == 'read'"
                    class="badge badge-info"
                    >{{ $t('generic-str.status.lbl-readed') }}</span
                  >
                  <span
                    v-if="message.status == 'accepted'"
                    class="badge badge-light"
                    >{{ $tc('generic-str.status.lbl-pending', 1) }}</span
                  >
                  <span
                    v-if="message.status == 'scheduled'"
                    class="badge badge-light"
                    >Agendado</span
                  >
                  <span
                    v-else-if="message.status == 'queued'"
                    class="badge badge-secondary"
                    >{{ $t('generic-str.status.lbl-queue') }}</span
                  >
                  <span
                    v-else-if="message.status == 'sending'"
                    class="badge badge-accent"
                    >{{ $t('generic-str.status.lbl-sending') }}</span
                  >
                  <span
                    v-else-if="message.status == 'sent'"
                    class="badge badge-info"
                    >{{ $t('generic-str.status.lbl-sent') }}</span
                  >
                  <span
                    v-else-if="message.status == 'failed'"
                    class="badge badge-danger"
                    v-tooltip.top="
                      `${$t('sms.infos.filters.status.lbl-error')} ${
                        message.error_code
                      }: ${message.error_message}`
                    "
                    >{{ $t('generic-str.status.lbl-failure') }}</span
                  >
                  <span
                    v-else-if="message.status == 'delivered'"
                    class="badge badge-success"
                    >{{ $t('generic-str.status.lbl-delivered') }}</span
                  >
                  <span
                    v-else-if="message.status == 'undelivered'"
                    class="badge badge-dark"
                    v-tooltip.top="
                      `${$t('sms.infos.filters.status.lbl-error')} ${
                        message.error_code
                      }: ${message.error_message}`
                    "
                    >{{ $t('generic-str.status.lbl-unavailable') }}</span
                  >
                  <span
                    v-else-if="message.status == 'received'"
                    class="badge badge-success"
                    >{{ $tc('generic-str.status.lbl-response', 1) }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="static qt-block-ui" style="padding: 120px" />
          <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
          <br />
          <pagination :lastPage="pages" @change="fetch" />
        </div>
        <div
          v-if="!messages.length && fetched"
          class="text-center table-placeholder"
        >
          <br /><br />
          <LottieAnimNoData />
          <h5 class="card-title m-t-20">{{ $t('wpp-component.none') }}</h5>
          <p>{{ $t('wpp-component.first-msg') }}</p>
          <router-link
            to="/whatsapp/send"
            type="button"
            class="btn btn-yup-purple"
            >{{ $t('wpp-component.new-msg') }}</router-link
          >
          <br /><br />
        </div>
      </div>
      <div class="card-footer">
        <button
          @click="exportMessages"
          class="btn btn-yup-purple"
          :class="{ 'qt-loader qt-loader-mini qt-loader-left': exporting }"
        >
          {{ $t('generic-str.export') }}
          <!-- Exportar -->
        </button>
        <router-link to="/whatsapp/exports" class="btn btn-yup-purple-outline btn-export">{{
          $t('sms.history.lbl-reports')
        }}</router-link>
        <!-- Relatórios Exportados -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import WhatsService from '@/services/whatsapp.service';
import CustomerService from '@/services/customer.service';
// import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
// import moment from 'moment';
import SelectInput from '@/components/SelectInput.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'WhatsAppHistoryTab',
  components: {
    // PageHeader,
    Pagination,
    SelectInput,
    LottieAnimNoData,
  },
  data() {
    return {
      exporting: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      customers: [],
      messages: [],
      beginDate: '',
      endDate: '',
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        channel: 'whatsapp',
        created_at: {
          gte: '',
          lte: '',
        },
        page: 1,
      },
      pages: 1,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }
  },
  mounted() {
    this.changeDate();
  },
  methods: {
    exportMessages() {
      this.exporting = true;
      WhatsService.exportMessages({
        channel: 'whatsapp',
        name: 'whats-report',
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/whatsapp/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      console.log(this.form);
      WhatsService.filterMessages(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
          console.log(response);
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}

.card.borderless .table td,
.card.borderless .table th,
.card.borderless .table thead th {
  border-bottom: none;
  border-top: none;
}
</style>
